import React, { useCallback, useEffect, useState } from 'react'
import { Box, Button, Checkbox, CircularProgress, Divider, FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, Typography, useMediaQuery, useTheme } from '@mui/material';
import GenericTextField from 'app/components/genericTextField/GenericTextField';
import { useAppDispatch } from 'core/hooks/reduxHooks';
import { useFormik } from 'formik';
import { Validations } from 'core/utils/validations';
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { formatDocument, removeNonNumeric } from 'core/utils/globalFunctions';
import theme from 'core/theme/theme';
import EmailIcon from '@mui/icons-material/Email';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import CallIcon from '@mui/icons-material/Call';
import { ContactInfos, RegisterContainer, RegisterContentBox, UserInfos } from './styles';
import { projectService } from 'core/api/project/projectService';
import { TRegisterRequester } from 'core/models/registerRequester/registerRequester';
import UserService from 'core/api/user/userService';
import { useNavigate } from 'react-router-dom';
import { values } from 'pdf-lib';
import BadgeIcon from '@mui/icons-material/Badge';
import { Widgets } from '@mui/icons-material';



const RegisterRequester = () => {
  const navigate = useNavigate();
  const currentTheme = useTheme();
  const isSmallScreen = useMediaQuery(currentTheme.breakpoints.down("sm"));
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  function togglePassword() {
    setShowPassword(!showPassword);
  }

  const projectOptions = [
    {
      id: 1,
      name: "LIMPANOME",
      status: "projeto positivo, limpa nome",
    },
    {
      id: 2,
      name: "LBS",
      status: "projeto lbs",
    },
    {
      id: 3,
      name: "POSICARD",
      status: "projeto posicard",
    },
  ];


  const initialValues: TRegisterRequester = {
    cpf: "",
    name: "",
    password: "",
    confirmPassword: "",
    projectId: [],
    contacts: {
      telephone: "",
      cellPhone1: "",
      cellPhone2: "",
      email: "",
    },
  };

  const handleRegister = async (values: any) => {
    setIsLoading(true);
    const newValues = {
      ...values,
      cpf: removeNonNumeric(values.cpf),
      contacts: {
        ...values.contacts,
        cellPhone1: removeNonNumeric(values.contacts.cellPhone1),
        cellPhone2: removeNonNumeric(values.contacts.cellPhone2),
        telephone: removeNonNumeric(values.contacts.telephone),
      },
    };
    await UserService.registerRequester(newValues)
      .then((response) => {
        setIsLoading(false);
        navigate("/login");
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }

  const formik = useFormik({
    validateOnChange: false,
    initialValues,
    validationSchema: Validations.registerRequesterSchema,
    onSubmit(values, formikHelpers) {
      handleRegister(values);
    },
  });

  const handleProjectChange = (event: any) => {
    formik.setFieldValue("projectId", event.target.value);
  };


  return (
    <RegisterContainer
      sx={{ display: "flex" }}
      $small={isSmallScreen}
      maxWidth={isSmallScreen ? "sm" : "md"}
      disableGutters
    >
      <Typography
        sx={{
          fontSize: "1.1pc",
          fontWeight: "bold",
          width: "100%",
          textAlign: "center",
        }}
      >
        REGISTRAR CONTA
      </Typography>

      <RegisterContentBox onSubmit={formik.handleSubmit} $small={isSmallScreen} style={{ padding: "0 3%", gap: "60px" }}>

        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", width: "100%", gap: 2 }}>

          <UserInfos $small={isSmallScreen}>
            <Typography sx={{ fontWeight: "bold" }}>
              Informações pessoais
            </Typography>
            <GenericTextField<string>
              props={{
                fullWidth: true,
                onChange: formik.handleChange,
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <BadgeIcon />
                    </InputAdornment>
                  ),
                },
              }}
              onBlur={() => {
                formik.setFieldValue("cpf", formatDocument(formik.values.cpf));
              }}
              value={formik.values.cpf}
              label="CPF/CNPJ"
              name="cpf"
              error={!!formik.errors.cpf}
              helperText={formik.errors.cpf}
            />

            <GenericTextField<string>
              props={{
                fullWidth: true,
                onChange: formik.handleChange,
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <PersonOutlineIcon />
                    </InputAdornment>
                  ),
                },
              }}
              value={formik.values.name}
              label="Nome"
              name="name"
              error={!!formik.errors.name}
              helperText={formik.errors.name}
            />

            <FormControl size={"small"} sx={{ width: "100%" }}>
              <InputLabel>Projetos</InputLabel>
              <Select
                multiple
                name="projectId"
                input={<OutlinedInput label="Projetos" />}
                value={formik.values.projectId}
                onChange={handleProjectChange}
                fullWidth
                size='small'
                sx={{
                  margin: !!formik.errors.projectId ? 0 : 0.5,
                }}
                inputProps={{
                  style: {
                    padding: "8.5px 0 8.5px 14px",
                  },
                }}
                renderValue={(selected) =>
                  selected
                    .map((id) => projectOptions.find((project) => project.id === id)?.name)
                    .join(", ")
                }
                error={!!formik.errors.projectId}
              >
                {projectOptions.map((project) => (
                  <MenuItem key={project.id} value={project.id}>
                    <Checkbox
                      checked={formik.values.projectId.includes(project.id)}
                    />
                    <ListItemText primary={project.name} />
                  </MenuItem>
                ))}
              </Select>
              {formik.errors.projectId && (
                <FormHelperText error>
                  {String(formik.errors.projectId)}
                </FormHelperText>
              )}
            </FormControl>

            <Box sx={{ display: "flex", alignItems: "center", width: "100%", flexDirection: "row", gap: 1 }}>
              <GenericTextField<string>
                props={{
                  type: showPassword ? "text" : "password",
                  fullWidth: true,
                  onChange: formik.handleChange,
                  InputProps: {
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={togglePassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  },
                }}
                value={formik.values.password}
                style={{ width: "100%" }}
                label="Senha"
                name="password"
                error={!!formik.errors.password}
                helperText={formik.errors.password}
              />
              <GenericTextField<string>
                props={{
                  type: showPassword ? "text" : "password",
                  fullWidth: true,
                  onChange: formik.handleChange,
                  InputProps: {
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={togglePassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  },
                }}
                value={formik.values.confirmPassword}
                label="Confirmar senha"
                style={{ width: "100%" }}
                name="confirmPassword"
                error={!!formik.errors.confirmPassword}
                helperText={formik.errors.confirmPassword}
              />
            </Box>
          </UserInfos>

          <Divider orientation="vertical" />

          <ContactInfos $small={isSmallScreen}>
            <Typography sx={{ fontWeight: "bold" }}>
              Informações de contato
            </Typography>
            <GenericTextField<string>
              props={{
                fullWidth: true,
                onChange: formik.handleChange,
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <EmailIcon />
                    </InputAdornment>
                  ),
                },
              }}
              value={formik.values.contacts.email}
              label="Email"
              name="contacts.email"
              error={!!formik.errors.contacts?.email}
              helperText={formik.errors.contacts?.email}
            />
            <GenericTextField<string>
              props={{
                fullWidth: true,
                onChange: formik.handleChange,
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <CallIcon />
                    </InputAdornment>
                  ),
                },
              }}
              onBlur={() => {
                formik.setFieldValue("contacts.telephone", formatDocument(formik.values.contacts.telephone));
              }}
              value={formik.values.contacts.telephone}
              label="Telefone"
              name="contacts.telephone"
              error={!!formik.errors.contacts?.telephone}
              helperText={formik.errors.contacts?.telephone}
            />
            <GenericTextField<string>
              props={{
                fullWidth: true,
                onChange: formik.handleChange,
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <PhoneAndroidIcon />
                    </InputAdornment>
                  ),
                },
              }}
              onBlur={() => {
                formik.setFieldValue("contacts.cellPhone1", formatDocument(formik.values.contacts.cellPhone1));
              }}
              value={formik.values.contacts.cellPhone1}
              label="Celular Principal"
              name="contacts.cellPhone1"
              error={!!formik.errors.contacts?.cellPhone1}
              helperText={formik.errors.contacts?.cellPhone1}
            />
            <GenericTextField<string>
              props={{
                fullWidth: true,
                onChange: formik.handleChange,
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <PhoneAndroidIcon />
                    </InputAdornment>
                  ),
                },
              }}
              onBlur={() => {
                formik.setFieldValue("contacts.cellPhone2", formatDocument(formik.values.contacts.cellPhone2));
              }}
              value={formik.values.contacts.cellPhone2}
              label="Celular Secundário"
              name="contacts.cellPhone2"
              error={!!formik.errors.contacts?.cellPhone2}
              helperText={formik.errors.contacts?.cellPhone2}
            />

          </ContactInfos>
        </Box>
        {isLoading ? (
          <CircularProgress color="secondary" />
        ) : (
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", flexDirection: "column", gap: 2 }}>
            <Button
              disabled={isLoading}
              type={"submit"}
              sx={{
                fontWeight: "bold",
                borderRadius: 1,
                height: "45px",
              }}
              variant="contained"
            >
              {isLoading ? <CircularProgress size={20} /> :
              <Typography>REGISTRAR-SE</Typography>}
            </Button>

          </Box>

        )}
      </RegisterContentBox>
    </RegisterContainer>
  )
}



export default RegisterRequester

