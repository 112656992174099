import { Box, Typography } from "@mui/material";
import theme from "core/theme/theme";
import styled from "styled-components";

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
`;

export const Header = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const  ButtonContainer = styled(Box) <{ $isRequester: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => props.$isRequester ? "center" : "flex-start"};
  align-items: center;
  width: 35%;
  padding: 0;
  margin: 0;
`;

export const StatusContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 20%;
`;

export const StatusItem = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  justify-content: flex-start;
`;

export const Circle = styled(Box)`
  width: 10px;
  height: 10px;
  border-radius: 50%;
`;

export const Content = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: 10;
  margin-top: 10px;
`;

export const OptionsContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 2%;
  align-items: center;
  margin: 20px;
`;

export const TableTitleContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  align-items: center;
`;

export const SearchContainer = styled(Box)`
  padding-right: 1rem;
  width: 300px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

export const TableHeader = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  margin-top: 10px;
`;

export const TableContent = styled(Box)`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 700px;
  justify-content: center;
  align-items: center;
  gap: 1.4rem;
`;

export const TableTitle = styled(Typography)`
  font-size: 30px !important;
  font-weight: bolder !important;
  color: ${theme.COLORS.GRAY2};
  padding-left: 2rem !important;
`;

export const Wellcome = styled(Typography)`
  font-size: 30px !important;
  width: 100%;
  font-weight: bolder !important;
  color: ${theme.COLORS.GRAY2};
  text-align: flex-start !important;
  margin-left: 57px !important;
`;

export const Title = styled(Typography)`
  font-weight: bold !important;
  width: 150px !important;
  text-align: center !important;
  color: gray;
`;

export const Priority = styled(Typography)`
  font-weight: bold !important;
  width: 110px !important;
  color: gray;
`;

export const UpdatedAt = styled(Typography)`
  font-weight: bold !important;
  width: 110px !important;
  color: gray;
`;

export const CreatedAt = styled(Typography)`
  font-weight: bold !important;
  width: 110px;
  color: gray;
`;

export const Status = styled(Typography)`
  font-weight: bold !important;
  text-align: center !important;
  width: 110px !important;
  color: gray;
`;

export const Atribuir = styled(Typography)`
  font-weight: bold !important;
  text-align: left !important;
  width: 50px !important;
  color: gray;
`;

export const CircularProgressBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
`;
