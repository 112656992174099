import { Box, Typography } from "@mui/material";
import theme from "core/theme/theme";
import PersonIcon from "@mui/icons-material/Person";
import styled from "styled-components";
import Timeline from "@mui/lab/Timeline";

export const Container = styled(Box)`
  display: flex;
  flex-direction: row;
  flex: 1;
  padding: 2%;
  gap: 2%;
`;

export const LeftContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: 2;
  width: 100%;
  gap: 3%;
`;

export const RequestTitle = styled(Typography)`
  font-size: 25px !important;
  font-weight: 1000 !important;
  color: ${theme.COLORS.GRAY3};
  text-align: start !important;
  width: 100%;
`;

export const DescriptionContainer = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const DescriptionTitle = styled(Typography)`
  font-size: 20px !important;
  font-weight: bold !important;
  color: ${theme.COLORS.GRAY2};
`;

export const DescriptionContent = styled(Box)`
  border: 2px solid ${theme.COLORS.GRAY5} !important;
  border-radius: 5px !important;
  padding: 16px !important;
  max-height: 400px !important;
  overflow-y: auto !important;
  width: 90%;
`;

export const DescriptionText = styled(Typography)`
  font-size: 14px !important;
  font-weight: bold !important;
  color: ${theme.COLORS.GRAY2};
`;

export const ChatContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 2%;
  width: 100%;
  height: 400px;
  gap: 3%;
`;

export const CommentQuantity = styled(Box)`
  display: flex;
  flex-direction: row;
  width: 90%;
  gap: 2%;
`;

export const Quantity = styled(Typography)`
  font-size: 18px !important;
  font-weight: bold !important;
  color: ${theme.COLORS.GRAY2};
`;

export const CommentContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-height: 400px;
  overflow-y: auto;
  width: 90%;
`;

export const CommentContent = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 1%;
  align-items: center;
`;

export const PersonIconStyled = styled(PersonIcon) <{ $isRequester: boolean }>`
  border-radius: 50%;
  width: 28px;
  height: 28px;
  color: ${theme.COLORS.WHITE};
  background: ${(props) => props.$isRequester ? theme.COLORS.DARKBLUE2 : theme.COLORS.GREEN1};
`;

export const CommentText = styled(Typography)`
  font-size: 16px !important;
  color: ${theme.COLORS.GRAY2};
`;

export const CommentBox = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 90%;
  gap: 1%;
`;

export const UserBox = styled(Box)`
  display: flex;
  flex-direction: row;
  width: 90%;
  gap: 1%;
`;

export const NameUserChat = styled(Typography)`
  font-size: 16px !important;
  font-weight: bold !important;
  color: ${theme.COLORS.GRAY2};
`;

export const DataComment = styled(Typography)`
  color: ${theme.COLORS.GRAY2};
  `;


export const InputContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 1%;
  align-items: center;
  width: 100%;
`;

export const IconContainer = styled(Box)`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
`;


export const RightContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  gap: 3%;
`;

export const ReletorAndExecutor = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  gap: 18px !important;;
  `;

export const ReletorContainer = styled(Box)`
  `;
export const ExecutorContainer = styled(Box)`
  `;

export const InfoBox = styled(Box)`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  border: 1px solid;
  border-radius: 3px;
  gap: 3% !important; /* Ajuste para 3% */
  background: ${(props) => props.theme.COLORS.GRAY7};
  padding: 0.3rem;
`;

export const AvatarBox = styled(Box)`
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: ${(props) => props.theme.COLORS.GREEN1};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NameUser = styled(Typography)`
  font-size: 15px !important;
  font-weight: bold !important;
  color: ${(props) => props.theme.COLORS.GRAY2} !important;
`;
export const IconLetter = styled(Typography)`
  font-size: 15px !important;
  font-weight: bold !important;
  color: ${(props) => props.theme.COLORS.WHITE} !important;
`;

export const InfoContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  margin-top: 10px;
  border: 1px solid;
  border-radius: 3px;
  padding: 0.3rem;
  gap: 13px !important;
  background: ${(props) => props.theme.COLORS.GRAY7};
`;

export const InfoRow = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const InfoText = styled(Typography)`
  font-weight: bold !important;
  color: ${(props) => props.theme.COLORS.GRAY2} !important;
`;

export const DateText = styled(Typography)`
  font-size: 14px !important;
  color: ${(props) => props.theme.COLORS.GRAY2} !important;
`;

export const HistoryContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 400px;
  `;
export const HistoryContent = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  border: 1px solid;
  border-radius: 3px;
  max-height: 200px;
  overflow-y: auto;
  padding: 0.3rem;
  gap: 3% !important; /* Usando porcentagem */
  background: ${(props) => props.theme.COLORS.GRAY7};
`;

export const HistoryText = styled(Typography)`
  font-size: 16px !important;
  font-weight: bold !important;
  color: ${(props) => props.theme.COLORS.GRAY2} !important;
`;


export const ImageContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 1%;
  width: 100%;
  `